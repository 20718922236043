!(function(win){
	'use strict';

	var upload = function(file, postData){
		postData = postData || {};
		var reader = new FileReader;
		var p = new Promise(function(ok,error){
			reader.addEventListener('load',function(){
				var code = sha1(reader.result);
				fetch( win.uploadHost  + '/exist?code='+code.toUpperCase())
					.then( function(res){ return  res.json(); })
					.then( function(json) {
						if(json.bizContent && json.bizContent.id ){
							return ok(json);
						}
						var data = new FormData();
						for(var k in postData){
							data.append(k, postData[k]);
						}
						data.append('file',file);
						return fetch(win.uploadHost + '/upload', {
							method: 'POST',
							body: data
						}).then(function (res) {
							return res.json()
						}).then(function (json) {
							return ok(json);
						});
					});
			});
		});
		reader.readAsArrayBuffer(file);
		return p;
	};

	win.fileUpload = function(file, postData){
		return upload(file, postData);
	};
})(window);